var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" fill=\"none\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M3.1 20.64h-.5c0 .27.22.5.5.5v-.5zm18.16 0v.5a.5.5 0 0 0 .5-.5h-.5zm0-15.65h.5a.5.5 0 0 0-.5-.5V5zM3.1 5v-.5a.5.5 0 0 0-.5.5h.5zM8.06 3a.5.5 0 0 0-1 0h1zm-1 3.97a.5.5 0 1 0 1 0h-1zM17.3 3a.5.5 0 0 0-1 0h1zm-1 3.97a.5.5 0 1 0 1 0h-1zm-6.43 5.16l.35-.35-.35.35zm0 3.72l-.35-.35.35.35zm-3.72 0l.35-.35-.35.35zM3.1 21.14h18.16v-1H3.1v1zm18.66-.5V4.99h-1v15.65h1zm-.5-16.15H3.1v1h18.16v-1zM2.6 5v15.65h1V4.99h-1zM7.06 3v3.97h1V3h-1zm9.23.01v3.97h1V3.01h-1zM3 10.01h18.36v-1H3v1zm6.52 2.49H9.5l.7-.71a.97.97 0 0 0-.69-.29v1zm-.01 0v-.01l-.01-.01h1a.97.97 0 0 0-.29-.7l-.7.71zm-.01-.02v3.04h1v-3.04h-1zm0 3.04v-.01l.71.7a.97.97 0 0 0 .29-.69h-1zm0-.01h.01l.01-.01v1c.28 0 .52-.11.7-.29l-.71-.7zm.02-.01H6.48v1h3.04v-1zm-3.04 0h.01l-.7.71c.17.18.42.29.69.29v-1zm.01 0v.01l.01.01h-1c0 .28.11.52.29.7l.7-.71zm.01.02v-3.04h-1v3.04h1zm0-3.04v.01l-.71-.7a.97.97 0 0 0-.29.69h1zm0 .01h-.01l-.01.01v-1a.97.97 0 0 0-.7.29l.71.7zm-.02.01h3.04v-1H6.48v1z\" fill=\"currentColor\"/></svg>";
},"useData":true});