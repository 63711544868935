var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 24 24\" fill=\"none\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><path d=\"M13.95 12.25l-.4.3a.5.5 0 0 0 .8 0l-.4-.3zm3.11-5.94l.5.07-.5-.07zm-6.24 0l-.5.05.5-.05zM3.7 7.57l.19-.46a.5.5 0 0 0-.68.43l.5.03zm8.04 1.35a.5.5 0 1 0-.38-.93l.38.93zm4.71-.86a.5.5 0 0 0-.33.94l.33-.94zm2.9 1.56l.49-.06a.5.5 0 0 0-.33-.41l-.17.47zM20.68 20l-.15.48a.5.5 0 0 0 .64-.54l-.5.06zm-11.94.07l-.17.47a.5.5 0 0 0 .35 0l-.18-.47zM3 17.98l-.5-.03a.5.5 0 0 0 .33.5l.17-.47zm11.65-3.52a.5.5 0 0 0-1 .07l1-.07zm-.7-2.2l.4.3v-.01l.01-.01a5.69 5.69 0 0 0 .12-.16 28.81 28.81 0 0 0 2.43-4.01c.31-.68.57-1.37.65-2l-1-.12a6.33 6.33 0 0 1-.56 1.7c-.3.63-.66 1.3-1.03 1.89a31.03 31.03 0 0 1-1.3 1.95l-.09.12c0 .02-.01.03-.02.03v.01l.4.3zm3.6-5.88a3.48 3.48 0 0 0-3.61-3.88v1c1.83 0 2.8 1.46 2.63 2.75l.99.13zM13.95 2.5a3.54 3.54 0 0 0-3.61 3.86l1-.1a2.54 2.54 0 0 1 2.6-2.76v-1zm-3.61 3.86c.05.6.3 1.3.6 1.97.32.68.72 1.38 1.1 2a31.41 31.41 0 0 0 1.53 2.23l.4-.3.4-.31h-.01v-.01l-.03-.03a22.3 22.3 0 0 1-.42-.58c-.27-.39-.64-.93-1.02-1.53-.37-.6-.74-1.26-1.03-1.89-.3-.64-.49-1.2-.53-1.64l-1 .09zM3.5 8.03l5.06 2.02.37-.93-5.06-2.01-.37.92zm5.43 2.02l2.79-1.13-.38-.93-2.78 1.13.37.93zM16.11 9l3.06 1.1.33-.95-3.06-1.09-.33.94zm2.73.68l1.35 10.39 1-.13-1.36-10.38-1 .12zm2 9.85l-6.31-2.02-.3.95 6.3 2.02.31-.95zm-6.64-2.01L8.58 19.6l.35.94 5.62-2.09-.35-.93zM8.92 19.6L3.17 17.5l-.34.94 5.75 2.09.34-.94zM8.25 9.59v10.48h1V9.59h-1zM3.2 7.54l-.7 10.41 1 .07.7-10.42-1-.06zm10.45 6.99l.22 3.49 1-.07-.22-3.49-1 .07zM14.5 6a.5.5 0 0 1-.5.5v1c.83 0 1.5-.67 1.5-1.5h-1zm-.5.5a.5.5 0 0 1-.5-.5h-1c0 .83.67 1.5 1.5 1.5v-1zm-.5-.5c0-.28.22-.5.5-.5v-1c-.83 0-1.5.67-1.5 1.5h1zm.5-.5c.28 0 .5.22.5.5h1c0-.83-.67-1.5-1.5-1.5v1z\" fill=\"currentColor\"/></svg>";
},"useData":true});