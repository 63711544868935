var Handlebars = require("../../../../../node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "m-icon--"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"size") || (depth0 != null ? lookupProperty(depth0,"size") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"size","hash":{},"data":data,"loc":{"start":{"line":1,"column":111},"end":{"line":1,"column":119}}}) : helper)));
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<svg viewbox=\"0 0 80 80\" fill=\"none\" width=\"0\" height=\"0\" aria-hidden=\"true\" class=\"m-icon "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"size") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":91},"end":{"line":1,"column":126}}})) != null ? stack1 : "")
    + " "
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"class") || (depth0 != null ? lookupProperty(depth0,"class") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"class","hash":{},"data":data,"loc":{"start":{"line":1,"column":127},"end":{"line":1,"column":136}}}) : helper)))
    + "\"><circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"currentColor\"/><mask style=\"mask-type:alpha\" maskunits=\"userSpaceOnUse\" x=\"0\" y=\"0\"><circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"currentColor\"/></mask><g mask=\"url(#a)\" fill=\"currentColor\"><path d=\"M52.64 47.73c2.95-5.43 4.84-14.7.59-20.13-7.2-9.16-22.66-8.55-26.55 3.02-1.06 3.25-.59 8.91-.83 15.3-.23 6.39 2.36 7.47 6.26 7.71l1.41.24c-.23 6.5-.59 7.35-.94 9.4 2.24 16.75 10.27 10.48 20.06-1.32 0-1.21-.83-5.43 0-14.1v-.12z\" fill=\"currentColor\"/><path d=\"M57.42 45.47a4.65 4.65 0 0 0 0-6.48 4.42 4.42 0 0 0-6.33.34 4.65 4.65 0 0 0 0 6.48 4.42 4.42 0 0 0 6.33-.34zM29.87 45.27a4.65 4.65 0 0 0 0-6.48 4.42 4.42 0 0 0-6.33.34 4.65 4.65 0 0 0 0 6.48 4.42 4.42 0 0 0 6.33-.34z\" fill=\"currentColor\"/><path d=\"M41.78 47.97h-6.96s-.35 2.65 3.54 2.53c3.9-.12 3.66-1.33 3.66-2.41 0 0 0-.24-.12-.24l-.12.12z\" fill=\"currentColor\"/><path d=\"M28.33 64.36l6.02-1.93 18.29-.84 2.71.48-13.21 13.01h-5.2l-8.6-10.72z\" fill=\"currentColor\"/><path d=\"M31.75 63.03C17.12 64.23 15 71.47 15 71.47s8.02 9.88 25.72 9.16c17.7-.73 27.38-12.17 27.38-12.17s-1.77-7-14.52-6.87c-1.65 4.1-6.72 10.12-15.22 12.77-4.95-3.73-6.84-11.09-6.49-11.2l-.12-.13z\" fill=\"currentColor\"/><path d=\"M49.34 15.43s8.85.73 8.5 8.32a78.19 78.19 0 0 1-2.37 13.98l-2.83 4.46V28.33c-1.42-.48-4.37.24-7.55 3.37 0-3.37 1.06-4.1 2.95-6.02-2.95-.49-4.25-.97-9.8 2.53 1.07-3.26 2.37-4.1 4.25-5.06-6.49 0-15.8 4.21-16.16 8.55l-.24 4.46s-8.26-5.3-4.24-15.3c3.65-9.04 19.23-12.17 27.49-5.43z\" fill=\"currentColor\"/></g></svg>";
},"useData":true});